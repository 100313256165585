import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

import { ACCESS_TOKEN_KEY, EMPTY_AUTH_HEADER_TOKEN, ORG_ID_KEY } from '@constants';
import { environment } from '@env/environment';
import { Customer } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class SessionStorage {
  #fingerprint: string = '';
  orgId$ = new BehaviorSubject<string | null>(null);
  loader$ = new BehaviorSubject(false);
  user$ = new BehaviorSubject<Customer>({} as Customer);

  constructor(private title: Title) {}

  getAuthHeader(): string {
    const token = this.getAccessToken();

    return token ? `Bearer ${token}` : EMPTY_AUTH_HEADER_TOKEN;
  }

  getFingerprintHeader(): string {
    return this.#fingerprint;
  }

  getAccessToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN_KEY) || null;
  }

  getOrgId(): string | null {
    return this.orgId$?.getValue() || localStorage.getItem(ORG_ID_KEY) || null;
  }

  getSubDomain(): string {
    const host = window.location.host;
    if (host.includes('localhost')) {
      return environment.storeDomain;
    }

    if (host.includes('.stage')) {
      const indexOfStage = host.indexOf('.stage');
      return host.substring(0, indexOfStage);
    }

    if (host.includes('absolut')) {
      const indexOfProd = host.indexOf('.s.absolut');
      return host.substring(0, indexOfProd);
    }

    return '';
  }

  setTitle(text: string) {
    this.title.setTitle(text);
  }

  setPointsPage(isPoints: boolean) {
    const body = document.getElementsByTagName('body')[0];
    if (isPoints) {
      body.classList.add('points-page');
    } else {
      body.classList.remove('points-page');
    }
  }

  getUserPoints() {
    return this.user$.getValue()?.points;
  }
}
