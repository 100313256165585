import { DialogModule } from '@angular/cdk/dialog';
import { PortalModule } from '@angular/cdk/portal';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { IMaskModule } from 'angular-imask';

import { BackButtonModule, ButtonModule, IconModule, MyButtonModule, NotificationModule } from '@nomia/nomia-components-lib';

import { ApolloModule } from '@api';
import { AppRoutingModule } from '@app/app-routing.module';
import { ConfigService } from '@app/config.service';
import { NotificationsModule } from '@app/shared/component/notifications/notifications.module';
import { OrganizationGuard } from '@core';
import { TraceService } from '@sentry/angular';
import { SharedModule } from '@shared';

import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    DialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    IMaskModule,
    GoogleTagManagerModule.forRoot({ id: 'GTM-5KCRCTG' }),
    IconModule,
    BackButtonModule,
    ButtonModule,
    NotificationModule,
    MyButtonModule,
    PortalModule,
    NotificationsModule,
    ApolloModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => () => configService.config$,
      deps: [ConfigService],
      multi: true,
    },
    ApolloModule,
    OrganizationGuard,
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
