import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AvatarModule } from '@app/shared/component/avatar/avatar.module';
import { IconButtonModule } from '@app/shared/component/icon-button/icon-button.module';
import { ListItemComponent } from '@app/shared/component/list-item/list-item.component';
import { SvgIconModule } from '@app/shared/component/svg-icon/svg-icon.module';

@NgModule({
  declarations: [ListItemComponent],
  imports: [CommonModule, AvatarModule, SvgIconModule, IconButtonModule],
  exports: [ListItemComponent],
})
export class ListItemModule {}
