import { NgModule } from '@angular/core';

import { CardBannerComponent } from '@app/shared/component/card-banner/card-banner.component';
import { SvgIconModule } from '@app/shared/component/svg-icon/svg-icon.module';

@NgModule({
  declarations: [CardBannerComponent],
  imports: [SvgIconModule],
  exports: [CardBannerComponent],
})
export class CardBannerModule {}
