import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SVG_ICONS_TYPE } from '@constants';

@Component({
  selector: 'nm-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemComponent {
  @Input() title: string = '';
  @Input() isTitleThin: boolean = false;
  @Input() description: string = '';
  @Input() descriptionUp: string = '';
  @Input() text: string = '';
  @Input() isTextThin: boolean = false;
  @Input() status: string = '';
  @Input() statusColor: 'blue' | 'red' | 'grey' = 'blue';
  @Input() iconLeft: SVG_ICONS_TYPE | null = null;
  @Input() iconRight: SVG_ICONS_TYPE | null = null;
  @Input() imageUrl: string = '';

  @Output() clickButton = new EventEmitter<void>();

  onClickButton(): void {
    this.clickButton.emit();
  }
}
