import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OptionComponent } from '@app/shared/component/option/option.component';
import { SvgIconModule } from '@app/shared/component/svg-icon/svg-icon.module';

@NgModule({
  declarations: [OptionComponent],
  exports: [OptionComponent],
  imports: [CommonModule, SvgIconModule],
})
export class OptionModule {}
