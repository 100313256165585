import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CardItemComponent } from '@app/shared/component/card-item/card-item.component';
import { OptionModule } from '@app/shared/component/option/option.module';
import { TagModule } from '@app/shared/component/tag/tag.module';

@NgModule({
  declarations: [CardItemComponent],
  exports: [CardItemComponent],
  imports: [CommonModule, TagModule, OptionModule],
})
export class CardItemModule {}
