import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChipModule } from '@app/shared/component/chip/chip.module';
import { ChipsComponent } from '@app/shared/component/chips/chips.component';
import { ControlHintModule } from '@app/shared/component/control-hint/control-hint.module';
import { ControlLabelModule } from '@app/shared/component/control-label/control-label.module';

@NgModule({
  declarations: [ChipsComponent],
  imports: [ControlLabelModule, ChipModule, ControlHintModule, CommonModule],
  exports: [ChipsComponent],
})
export class ChipsModule {}
