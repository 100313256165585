import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '@app/shared/component/button/button.module';
import { TitleH2Component } from '@app/shared/component/title-h2/title-h2.component';

@NgModule({
  declarations: [TitleH2Component],
  exports: [TitleH2Component],
  imports: [CommonModule, ButtonModule],
})
export class TitleH2Module {}
