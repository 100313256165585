import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { validate as isValidUUID } from 'uuid';

import { SessionStorage } from '@app/api';

@Injectable()
export class OrganizationGuard implements CanActivate {
  constructor(public sessionStorage: SessionStorage) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const {
      params: { id },
    } = route;

    if (isValidUUID(id)) {
      this.sessionStorage.orgId$.next(id);
    }

    return isValidUUID(id);
  }
}
