<div class="list-item">
  <div class="list-item-title">
    <nm-avatar *ngIf="imageUrl" type="square" size="40" cursor="default" [imageUrl]="imageUrl"></nm-avatar>

    <nm-svg-icon *ngIf="iconLeft" [color]="'#E3E2E6'" [size]="24" [name]="iconLeft"></nm-svg-icon>

    <div class="title-container">
      <span *ngIf="descriptionUp" class="descriptionUp">{{ descriptionUp }}</span>
      <span class="title" [class.title-thin]="isTitleThin">{{ title }}</span>
      <span *ngIf="description" class="description">{{ description }}</span>
    </div>
  </div>

  <div class="list-item-action">
    <span *ngIf="text" class="text" [class.title-thin]="isTextThin">{{ text }}</span>

    <span
      *ngIf="status"
      class="status"
      [class.status-blue]="statusColor === 'blue'"
      [class.status-red]="statusColor === 'blue'"
      [class.status-grey]="statusColor === 'blue'"
      >{{ status }}</span
    >

    <nm-icon-button
      *ngIf="iconRight"
      [iconColor]="'#E3E2E6'"
      [size]="24"
      [icon]="iconRight"
      [variant]="'text'"
      (clickButton)="onClickButton()"
    ></nm-icon-button>
  </div>
</div>
