import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconModule } from '@app/shared/component/svg-icon/svg-icon.module';
import { TagComponent } from '@app/shared/component/tag/tag.component';

@NgModule({
  declarations: [TagComponent],
  exports: [TagComponent],
  imports: [SvgIconModule, CommonModule],
})
export class TagModule {}
