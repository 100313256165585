import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RootNavigationRoute } from '@constants';
import { OrganizationGuard } from '@core';

const routes: Routes = [
  {
    path: ':id',
    canActivate: [OrganizationGuard], // Проверка на соответствие UUID
    // resolve: {
    //   user: UserResolver,
    // },
    children: [
      {
        path: '',
        redirectTo: '**',
        pathMatch: 'full',
      },
      {
        path: RootNavigationRoute.main,
        loadChildren: () => import('@app/modules/pages/main-page/main-page.module').then((m) => m.MainPageModule),
      },
      {
        path: RootNavigationRoute.points,
        loadChildren: () => import('@app/modules/pages/points/points.module').then((m) => m.PointsModule),
      },
      {
        path: RootNavigationRoute.profile,
        loadChildren: () => import('@app/modules/pages/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: `${RootNavigationRoute.booking}/:id`,
        loadChildren: () => import('@app/modules/pages/booking/booking.module').then((m) => m.BookingModule),
      },
      {
        path: RootNavigationRoute.stores,
        loadChildren: () => import('@app/modules/pages/stores/stores.module').then((m) => m.StoresModule),
      },
    ],
  },
  {
    path: '**',
    loadChildren: () => import('@app/modules/error/error.module').then((m) => m.ErrorModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
