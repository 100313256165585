import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SessionStorage } from '@app/api';
import { Customer } from '@typings';

// declare global {
//   interface Window {
//     Telegram: any;
//   }
// }

@Component({
  selector: 'nm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'mini-app';

  constructor(private renderer: Renderer2, private sessionStorage: SessionStorage, private activatedRoute: ActivatedRoute) {}
  ngOnInit(): void {
    this.sessionStorage.loader$.subscribe((isLoader) => {
      let loader = this.renderer.selectRootElement('#loader');
      if (isLoader) {
        this.renderer.setStyle(loader, 'display', 'none');
      } else {
        this.renderer.setStyle(loader, 'display', 'block');
      }
    });

    const user: Customer = this.activatedRoute.snapshot?.data?.['user'];
    this.sessionStorage.user$.next(user);

    // const tg = window.Telegram.WebApp;
    // if (tg) {
    //   console.log(tg)
    // }
  }
}
